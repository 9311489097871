<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('form.resourceblock.dates')">
						<v-row dense>
							<!-- STARTDATE -->
							<v-col cols="4">
								<pui-date-field
									:id="`startdate-resourceblock`"
									v-model="model.startdate"
									:label="$t('form.resourceblock.startdate')"
									:disabled="formDisabled"
									toplabel
									:max="model.enddate"
								></pui-date-field>
							</v-col>
							<!-- ENDDATE -->
							<v-col cols="4">
								<pui-date-field
									:id="`enddate-resourceblock`"
									v-model="model.enddate"
									:label="$t('form.resourceblock.enddate')"
									:disabled="formDisabled"
									toplabel
									:min="model.startdate"
								></pui-date-field>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('form.resourceblock.times')">
						<v-row dense>
							<!-- STARTTIME -->
							<v-col cols="4">
								<v-menu
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="150px"
									:disabled="formDisabled"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-on="on"
											v-bind="attrs"
											ref="resourceblock.enddate"
											v-model="model.starttime"
											append-icon="fa-clock"
											clearable
											solo
											outlined
											flat
											:disabled="formDisabled"
											class="ml-1"
											:placeholder="$t('form.resourceblock.starttime')"
										></v-text-field>
									</template>
									<v-time-picker
										v-model="model.starttime"
										:disabled="formDisabled"
										format="24hr"
										color="var(--primarycolor)"
										:max="model.endtime"
									></v-time-picker>
								</v-menu>
							</v-col>
							<!-- ENDTIME -->
							<v-col cols="4">
								<v-menu
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="150px"
									:disabled="formDisabled"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-on="on"
											v-bind="attrs"
											ref="resourceblock.starttime"
											v-model="model.endtime"
											append-icon="fa-clock"
											clearable
											solo
											outlined
											flat
											:disabled="formDisabled"
											class="ml-1"
											:placeholder="$t('form.resourceblock.endtime')"
										></v-text-field>
									</template>
									<v-time-picker
										v-model="model.endtime"
										:disabled="formDisabled"
										format="24hr"
										color="var(--primarycolor)"
										:min="model.starttime"
									></v-time-picker>
								</v-menu>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('form.resourceblock.days')">
						<v-row dense>
							<!-- DAYS -->
							<v-col cols="9">
								<v-select
									v-model="model.days"
									:items="allWeekDays"
									:itemsToSelect="model.days"
									itemValue="id"
									itemText="name"
									multiple
									solo
									flat
									hide-details
									:disabled="formDisabled"
									reactive
								></v-select>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="customSave" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'resourceblock-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'resourceblock',
			allWeekDays: []
		};
	},
	methods: {
		afterGetData() {
			this.$moment.locale(window.localStorage.getItem('pui9_lang'));
			this.allWeekDays = this.$moment.weekdays(true).map((d, index) => {
				return { id: index, name: d };
			});
			this.model.days = JSON.parse(this.model.days);
			if (!this.isCreatingElement) {
				if (this.model.starttime) {
					this.model.starttime = this.$moment(this.model.starttime).format('YYYY-MM-DD HH:mm:ss').substring(11, 16);
				}
				if (this.model.endtime) {
					this.model.endtime = this.$moment(this.model.endtime).format('YYYY-MM-DD HH:mm:ss').substring(11, 16);
				}
			}
		},
		customSave() {
			if (Array.isArray(this.model.days)) {
				this.model.days = this.model.days.sort((a, b) => a - b);
				this.model.days = JSON.stringify(this.model.days);
			}
			if (this.isCreatingElement) {
				this.model.resourceid = JSON.parse(atob(this.$attrs.parentpk)).resourceid;
			}
			this.model.starttime = this.$moment('1970-01-01 '.concat(this.model.starttime));
			this.model.endtime = this.$moment('1970-01-01 '.concat(this.model.endtime));
			this.save();
		}
	},
	computed: {},
	created() {},
	watch: {}
};
</script>
